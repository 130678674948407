"use client";
import { CentraCheckoutScript } from "@/features/checkout/CentraCheckout";
import { useHydrateLocalWishlist } from "@/features/wishlist/hooks";
import { DEFAULT_TIMEZONE } from "@/i18n";
import { useStateSetter } from "@/lib/centra/hooks/useSelection/useStateSetter";
import "@/lib/storyblok/initialiseBloks";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "core-js/features/array/to-sorted";
import { NextIntlClientProvider } from "next-intl";
import type { ReactNode } from "react";
import { useState } from "react";

interface IProvidersProps {
  children: ReactNode;
  locale: string;
  messages: Record<string, string>;
}

export default function Providers({
  children,
  locale,
  messages,
}: IProvidersProps) {
  const [queryClient] = useState(() => new QueryClient());
  useHydrateLocalWishlist();
  return (
    <QueryClientProvider client={queryClient}>
      <NextIntlClientProvider
        locale={locale}
        messages={messages}
        timeZone={DEFAULT_TIMEZONE}
      >
        <DataProviders>{children}</DataProviders>
        <CentraCheckoutScript />
      </NextIntlClientProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

const DataProviders = ({ children }: { children: React.ReactNode }) => {
  /**
   * This enables fallback state via GeoLocation and sets the state in Centra
   * Will only execute for select countries like US, CA, AU, NZ
   */
  useStateSetter();
  return <>{children}</>;
};
