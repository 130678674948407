"use client";

import { useSelection } from "@/lib/centra/hooks/useSelection";
import { default as Script } from "next/script";

export const CentraCheckoutScript = () => {
  const { data } = useSelection();
  const script = data?.selection.centraCheckoutScript;
  if (!script) return null;
  return (
    <Script
      id="centra-checkout-script"
      dangerouslySetInnerHTML={{
        __html: script
      }}
    />
  );
};

declare global {
  interface Window {
    CentraCheckout: {
      resume: (suspendIgnore?: Record<string, unknown>) => void;

      suspend: (suspendIgnore?: Record<string, unknown>) => void;

      reInitiate: (target: "ingrid") => void;

      destroy: () => void;
    };
  }
}

export const Centra = {
  resume: (suspendIgnore?: Record<string, unknown>) => {
    if (typeof window === "undefined") return;
    if (window.CentraCheckout) window.CentraCheckout.resume(suspendIgnore);
  },
  suspend: (suspendIgnore?: Record<string, unknown>) => {
    if (typeof window === "undefined") return;
    if (window.CentraCheckout) window.CentraCheckout.suspend(suspendIgnore);
  },
  reInitiate: (target: "ingrid") => {
    if (typeof window === "undefined") return;
    if (window.CentraCheckout) window.CentraCheckout.reInitiate(target);
  },
  destroy: () => {
    if (typeof window === "undefined") return;
    if (window.CentraCheckout) window.CentraCheckout.destroy();
  }
};
