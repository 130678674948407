import env from "@/env.mjs";
import { Centra } from "@/features/checkout/CentraCheckout";
import useEffectEvent from "@/hooks/useEffectEvent";
import usePersistedStore from "@/lib/stateManagement/persistedState/persistedStore";
import { CentraSelection } from "@frend-digital/centra/schemas/selection/selectionSchema";
import {
  useIsMutating,
  useMutation,
  useQuery,
  useQueryClient
} from "@tanstack/react-query";
import { Geo } from "@vercel/edge";
import { ofetch } from "ofetch";
import { useEffect, useMemo } from "react";
import formatSelection from "../../formatters/formatSelection";
import useSelection from "./useSelection";

const API_URL = env.NEXT_PUBLIC_CENTRA_CHECKOUT_API;

let HAS_SET_STATE = false;

export function useStateSetter() {
  const { data: selection } = useSelection();
  const findRegion = useFindCentraRegion();
  const token = usePersistedStore((state) => state.token);
  const { data: geoData } = useQuery({
    queryKey: ["geoData"],
    queryFn: ({ signal }) => ofetch<Geo>("/api/geo", { signal })
  });

  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationKey: ["state-update"],
    mutationFn: async ({
      state,
      country,
      token
    }: {
      state: string;
      country: string;
      token: string;
    }) => {
      if (HAS_SET_STATE) {
        return;
      }
      HAS_SET_STATE = true;
      Centra.suspend();
      const response = await ofetch<CentraSelection>(
        `${API_URL}/payment-fields`,
        {
          method: "PUT",
          headers: {
            "API-Token": token
          },
          body: {
            address: {
              state: findRegion({ region: state, country })
            }
          }
        }
      );
      Centra.resume();
      return response;
    },
    onSuccess: (data) => {
      data && queryClient.setQueryData(["selection"], formatSelection(data));
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["selection"] });
    }
  });

  const currentCountry = selection?.location?.country;

  const foundCountry = useMemo(() => {
    const countryConfig = selection?.countries.find(
      (c) => c?.country === currentCountry
    );
    return countryConfig;
  }, [currentCountry, selection?.countries]);

  const stateRequired = !!foundCountry?.states;

  const isMutating = useIsMutating({ mutationKey: ["state-update"] });
  useEffect(() => {
    if (
      !token ||
      !geoData?.countryRegion ||
      !geoData.country ||
      !currentCountry ||
      !stateRequired ||
      selection?.selection.address?.state ||
      !!isMutating
    ) {
      return;
    }

    mutate({
      state: geoData.countryRegion,
      country: currentCountry,
      token
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    geoData?.countryRegion,
    token,
    stateRequired,
    selection?.selection.address?.state,
    currentCountry
  ]);
}

export const useFindCentraRegion = () => {
  const { data: selection } = useSelection();

  const findRegion = useEffectEvent(
    ({ region, country }: { region: string; country: string }) => {
      const foundCountry = selection?.countries.find(
        (c) => c?.country === country
      );
      if (!foundCountry) {
        return undefined;
      }
      if (!foundCountry.states?.length) {
        return undefined;
      }

      const foundRegion =
        foundCountry.states.find((s) =>
          s.state.toUpperCase().includes(region.toUpperCase())
        ) || foundCountry.states[0];
      return foundRegion.state;
    }
  );

  return findRegion;
};
